<template>
  <v-container>
    <v-row class="mb-2">
      <v-col User="12" md="6">
        <h1>Group Test</h1>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="options.search"
          prepend-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <highcharts :options="chartOptions" class="mb-4"></highcharts>

    <v-data-table
      :headers="headers"
      :items="groups"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:[`item.action`]="{ item: { id } }">
        <div style="width: 100px">
          <v-btn
            :to="{ name: 'Result.Group.Test', params: { id } }"
            color="primary"
            class="mb-1"
            >Test List</v-btn
          >
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { Chart } from "highcharts-vue";
import { mapActions } from "vuex";
import Group from "@/services/group";
import Test from "@/services/test";

export default {
  name: "Result.Group.Index",
  data() {
    return {
      totalData: 0,
      groups: [],
      loading: true,
      options: {
        search: undefined
      },
      headers: [
        { text: "Group Name", value: "name" },
        { text: "Institute", value: "instituteName" },
        { text: "Average Score", value: "averageScore" },
        { text: "Action", value: "action" }
      ],
      chartData: []
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "column",
          scrollablePlotArea: {
            minWidth: 1500,
            scrollPositionX: 1
          }
        },
        title: {
          text: `Group Test Score`
        },
        xAxis: {
          type: "category"
        },
        yAxis: {
          title: {
            text: "Score"
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: "{point.y}"
            }
          }
        },
        series: [
          {
            name: "Group Test Score",
            colorByPoint: true,
            data: this.chartData
          }
        ]
      };
    }
  },
  components: {
    highcharts: Chart
  },
  watch: {
    options: {
      handler() {
        this.getGroups();
      },
      deep: true
    }
  },
  created() {
    this.setNavbarTitle("Group Result");
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getGroups() {
      const [groups, results] = await Promise.all([
        Group.index({ ...this.options, score: true }),
        Test.groupTestResult()
      ]);
      this.groups = groups.data.data.groups;
      this.results = results.data.data;
      this.totalData = groups.data.data.total_data;
      this.chartData = results.data.data.map(({ name, averageScore: y }) => ({
        name,
        y
      }));
      this.loading = false;
    }
  }
};
</script>
